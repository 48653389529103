































import ITabContent from "@/interfaces/ITabContent";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import Button from "@/components/Button.vue";

export default defineComponent({
  name: "Tabbar",
  components: {
    Button,
  },
  props: {
    hasTabs: {
      type: Boolean,
      default: true,
    },
    tabs: {
      type: Array as PropType<ITabContent[]>,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  setup(props, context) {
    return {
      filteredTabs: computed(() =>
        props.tabs.filter((element) => element.visible != false)
      ),
      currentIndex: computed({
        get(): number {
          return props.value;
        },
        set(newValue: number): void {
          context.emit("input", newValue);
        },
      }),
    };
  },
});
