import * as validators from "@vuelidate/validators";
import { i18n } from "@/i18n";
import Vue from "vue";

import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);

// or import { createI18nMessage } from '@vuelidate/validators'
const { createI18nMessage } = validators;

// extract the `t` helper, should work for both Vue 2 and Vue 3 versions of vue-i18n

// pass `t` and create your i18n message instance
// const withI18nMessage = createI18nMessage({t})

export const withI18nMessage = validators.createI18nMessage({
  t: i18n.t.bind(i18n) as (
    path: string,
    params: {
      [key: string]: any;
      model: string;
      property: string;
    }
  ) => string,
});

// wrap each validator.
export const required = withI18nMessage(validators.required);
// validators that expect a parameter should have `{ withArguments: true }` passed as a second parameter, to annotate they should be wrapped
export const minLength = withI18nMessage(validators.minLength, {
  withArguments: true,
});
// or you can provide the param at definition, statically
export const maxLength = withI18nMessage(validators.maxLength, {
  withArguments: true,
});

export const url = withI18nMessage(validators.url);
export const numeric = withI18nMessage(validators.numeric);
export const email = withI18nMessage(validators.email);
export const sameAs = withI18nMessage(validators.sameAs, {
  withArguments: true,
});
